//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
export default {
  components: {
    experienceSection: () =>
      import('@/components/sections/dynamic/experienceSection.vue'),
    heroSection: () => import('@/components/sections/dynamic/heroSection.vue'),
    experienceSectionSecond: () =>
      import('@/components/sections/dynamic/experienceSectionSecond.vue'),
    textSection: () => import('@/components/sections/dynamic/textSection.vue'),
    uploadsSection: () => import('@/components/sections/dynamic/uploadsSection.vue'),
    tableSection: () => import('@/components/sections/dynamic/tableSection.vue'),
    listSection: () => import('@/components/sections/dynamic/listSection.vue'),
    twoColumnImageTextSection: () =>
      import('@/components/sections/dynamic/twoColumnImageTextSection.vue'),
    textmediaSection: () =>
      import('@/components/sections/dynamic/textmediaSection.vue'),
    textMapSection: () =>
      import('@/components/sections/dynamic/textMapSection.vue'),
    spotheadlessHighlightsSection: () =>
      import('@/components/sections/dynamic/spotheadlessHighlightsSection.vue'),
    spotheadlessInfosSection: () =>
      import('@/components/sections/dynamic/spotheadlessInfosSection.vue'),
    toursSection: () =>
      import('@/components/sections/dynamic/toursSection.vue'),
    toursListSection: () =>
      import('@/components/sections/dynamic/toursListSection.vue'),
    gallerySection: () =>
      import('@/components/sections/dynamic/gallerySection.vue'),
    spotdiscoverswissAllSection: () =>
      import('@/components/sections/dynamic/spotdiscoverswissAllSection.vue'),
    highlightSection: () =>
      import('@/components/sections/dynamic/highlightSection.vue'),
    highlightSecondSection: () =>
      import('@/components/sections/dynamic/highlightSecondSection.vue'),
    bookingSection: () =>
      import('@/components/sections/dynamic/bookingSection.vue'),
    faqSection: () => import('@/components/sections/dynamic/faqSection.vue'),
    spotdiscoverswissAllSliderSection: () =>
      import(
        '@/components/sections/dynamic/spotdiscoverswissAllSliderSection.vue'
      ),
    mediaundinfluencerSection: () =>
      import('@/components/sections/dynamic/mediaundinfluencerSection.vue'),
    mapSpotdiscoverswissSection: () =>
      import('@/components/sections/dynamic/mapSpotdiscoverswissSection.vue'),
    gallerySpotdiscoverswissSection: () =>
      import(
        '@/components/sections/dynamic/gallerySpotdiscoverswissSection.vue'
      ),
  },
  data() {
    return {
      metaData: null,
      page: {},
      content: [],
      isLoading: false,
      loading: true,
    }
  },
  head() {
    return {
      title:
        this.metaData && this.metaData.title
          ? `RheinWelten - ${this.metaData.title}`
          : 'RheinWelten - Den Rhein per E-Bike entdecken.',
    }
  },
  computed: {
    ...mapGetters([
      'getFavourites'
    ]),
    redirectUrl() {
      return process.env.REDIRECT_URL
    },
    getCurrentRoute() {
      return this.$route.path.replace(/\//g, '-')
    },
  },
  created() {
    this.getContent()
  },
  async mounted(){
    await this.$store.dispatch('loadFavourites')
  },
  methods: {
    pagination(item, filter, $state) {
      this.isLoading = true
      this.getContent(
        {
          page: item.page,
          type: filter.type === 'alle' ? null : filter.type,
          category: filter?.categories?.length
            ? filter.categories.join(',')
            : [],
          experience: filter.experiences.length
            ? filter.experiences.join(',')
            : [],
          region: filter.regions.length ? filter.regions.join(',') : [],
        },
        $state
      )
    },

    filter(filter, page) {
      this.getContent({
        page,
        type: filter.type === 'alle' ? null : filter.type,
        category: filter?.categories?.length ? filter.categories.join(',') : [],
        experience: filter.experiences.length
          ? filter.experiences.join(',')
          : [],
        region: filter.regions.length ? filter.regions.join(',') : [],
      })
    },

    filterAdvance(filter) {
      this.getContent({
        page: 1,
        type: filter.type === 'alle' ? null : filter.type,
        category: filter?.categories?.length ? filter.categories.join(',') : [],
        experience: filter.experiences.length
          ? filter.experiences.join(',')
          : [],
        region: filter.regions.length ? filter.regions.join(',') : [],
      })
    },

    getContent(params, $state) {
      const route = this.$route.path
      if (!params) {
        this.$store.commit('SET_GLOBAL_LOADING', true)
      }
      this.isLoading = true
      this.$store
        .dispatch('getContentDetails', {
          url: route,
          params,
        })
        .then((res) => {
          this.page = res?.page
          this.content = res?.content?.colPos0
          this.metaData = res?.page
          this.$store.commit('SET_PARTNERS', res?.page?.company[0]?.partner)
        })
        .catch((err) => {
          this.$router.push('/error-404')
          return err
        })
        .finally(() => {
          this.isLoading = false
          if ($state) {
            $state.loaded()
          }
        })
    },
  },
}
